var back = $.contains(document.body, document.getElementById('zona-usuario'));

function calculateProgressBar (itemPuzzleCount, numberNext, interval = false) {
    var valTotal = (100 * numberNext) / itemPuzzleCount;
    $('#progress-bar-puzzle').width(valTotal + '%');
    if (interval) {
        initSetInterval(numberNext);
    }
}

function callAjaxFunction (event, thisForm) {
    event.preventDefault();
    $('.loading').fadeIn();

    if (!back) {
        var email = $('#credicard-email').val();
        if (email == '') {
            $('.alert-danger').html('Ha ocurrido un error al guardar el email.');
            $('.alert-danger').removeClass('d-none');
            $('.loading').hide();
            setTimeout(function () {
                $('.alert-danger').addClass('d-none');
            }, 5000);
            return;
        }
    } else {
        var email = '';
    }

    $.ajax({
        cache: false,
        type: thisForm.attr('method'),
        url: '/ajax.php?controller=AjaxSaveTest&back=' + back,
        data: thisForm.serialize() + '&email=' + email,
        success: function (data) {
            if (data.status == true) {
                if (!back) {
                    $(location).attr('href', '/propuesta-de-oferta/?code=' + data.code);
                } else {
                    $(location).attr('href', '/back/index.php?entity=10');
                    $('.loading').hide();
                }
            } else if (data.status == 'training') {
                $('.alert-danger').html(
                    '<h4>Ya has finalizado tu entrenamiento, te recomendamos continúes realizando más entrenamientos para activar y agilizar tu mente al 100 por 100. Pincha aquí para ir a <a href=\'/back/index.php?entity=12\'> más entrenamientos </a>.</h4>'
                );
                $('.alert-danger').removeClass('d-none');
                $('.alert').removeClass('alert-danger');
                $('.alert').addClass('alert-success');
                $('.content-right').addClass('d-none');
                $('.content-incorrect').addClass('d-none');
                $('.btn-warning').addClass('d-none');
                $('.loading').hide();
            } else {
                $('.alert-danger').html(data.error);
                $('.alert-danger').removeClass('d-none');
                $('.loading').hide();
            }
            setTimeout(function () {
                $('.alert-danger').addClass('d-none');
            }, 5000);
        }
    });
}

var internal;

function startTimer (duration, display, skip = false) {
    var timer = duration,
        minutes,
        seconds;

    internal = setInterval(function () {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        display.textContent = minutes + ':' + seconds;

        if (--timer < 0) {
            timer = duration;
            clearInterval(internal);
            if (skip) {
                $('#btn-next-puzzle').click();
            }
        }
    }, 1000);
}

function eventClickNextPuzzle (
    event,
    thisObject,
    itemPuzzleCount,
    puzzle_test_item,
    nombreIdPuzzle,
    nombreForm,
    intervalProgressBar = false,
    counter = false,
    seconds_counter = 0,
    skip_next_puzzle = false
) {
    event.preventDefault();
    var item = thisObject.data('item');
    index = thisObject.data('index');
    var index_next = index + 1;
    numberNext = index + 1;
    if (typeof arrayPuzzle !== 'undefined') {
        $('#' + puzzle_test_item + arrayPuzzle[index].puzzleId).val(item);
    } else {
        $('#' + puzzle_test_item + index).val(item);
    }
    calculateProgressBar(itemPuzzleCount, index_next, intervalProgressBar);
    if (counter) {
        accountant.textContent = '00:0' + seconds_counter;
        clearInterval(internal);
        startTimer(accountantMinutes, accountant, skip_next_puzzle);
    }
    if (index_next < itemPuzzleCount) {
        if (counter) {
            $('.restante').removeClass('d-none');
        }
        $('#numberPagPre').html(index_next + 1);
        $('#' + nombreIdPuzzle + ' .item-puzzle').addClass('d-none');

        if (typeof arrayPuzzle !== 'undefined') {
            $('#item-puzzle-' + arrayPuzzle[index_next].puzzleId).removeClass(
                'd-none'
            );
        } else {
            $('#item-puzzle-' + index_next).removeClass('d-none');
        }
    } else {
        if (back) {
            $('#' + nombreForm).submit();
        } else {
            $('#modal-form-email-puzzle').modal('show');
        }
    }
}

function eventClickNext (
    event,
    itemPuzzleCount,
    nombreIdPuzzle,
    nombreForm,
    intervalProgressBar = false
) {
    event.preventDefault();
    numberNext++;
    calculateProgressBar(itemPuzzleCount, numberNext, intervalProgressBar);
    if (numberNext < itemPuzzleCount) {
        $('#numberPagPre').html(numberNext + 1);
        $('#' + nombreIdPuzzle + ' .item-puzzle').addClass('d-none');

        if (typeof arrayPuzzle !== 'undefined') {
            $('#item-puzzle-' + arrayPuzzle[numberNext].puzzleId).removeClass(
                'd-none'
            );
        } else {
            $('#item-puzzle-' + numberNext).removeClass('d-none');
        }
    } else {
        if (back) {
            $('#' + nombreForm).submit();
        } else {
            $('#modal-form-email-puzzle').modal('show');
        }
    }
}

/*******************************************************/

if ($.contains(document.body, document.getElementById('container-puzzle'))) {
    var stringHtml = '';
    var numberNext = 0;
    var itemPuzzleCount = arrayPuzzle.length;
    var innerHtmlPuzzle = document.getElementById('container-puzzle');
    var globalNombreTest = 'form-puzzle-test';
    for (var i = 0; i < itemPuzzleCount; i++) {
        stringHtml +=
            '<div id="item-puzzle-' +
            arrayPuzzle[i].puzzleId +
            '" class="row item-puzzle d-none">';
        stringHtml += '<div class="col-12 col-lg-5">';
        stringHtml +=
            '<input type="hidden" id="puzzle_test_item_' +
            arrayPuzzle[i].puzzleId +
            '" name="puzzle_test_item[' +
            arrayPuzzle[i].puzzleId +
            ']" value="0" />';
        stringHtml +=
            '<div class="container-puzzle-main" style="background-image: url(\'' +
            arrayPuzzle[i].imageMain +
            '\');"></div>';
        stringHtml += '</div>';
        stringHtml += '<div class="col-12 col-lg-6 offset-lg-1">';
        stringHtml += '<h3>'+SELECCIONA_TU_RESPUESTA+'</h3>';
        stringHtml += '<div class="container-puzzle-items">';
        stringHtml += '<div class="row row-list-items">';
        stringHtml += '<div class="col-12 col-sm-4">';
        stringHtml +=
            '<div class="image-puzzle-item" data-item="1" data-index="' +
            i +
            '" style="background-image: url(\'' +
            arrayPuzzle[i].imageOpc1 +
            '\');"></div>';
        stringHtml += '</div>';
        stringHtml += '<div class="col-12 col-sm-4">';
        stringHtml +=
            '<div class="image-puzzle-item" data-item="2" data-index="' +
            i +
            '" style="background-image: url(\'' +
            arrayPuzzle[i].imageOpc2 +
            '\');"></div>';
        stringHtml += '</div>';
        stringHtml += '<div class="col-12 col-sm-4">';
        stringHtml +=
            '<div class="image-puzzle-item" data-item="3" data-index="' +
            i +
            '" style="background-image: url(\'' +
            arrayPuzzle[i].imageOpc3 +
            '\');"></div>';
        stringHtml += '</div>';
        stringHtml += '</div>';
        stringHtml += '<div class="row row-list-items">';
        stringHtml += '<div class="col-12 col-sm-4">';
        stringHtml +=
            '<div class="image-puzzle-item" data-item="4" data-index="' +
            i +
            '" style="background-image: url(\'' +
            arrayPuzzle[i].imageOpc4 +
            '\');"></div>';
        stringHtml += '</div>';
        stringHtml += '<div class="col-12 col-sm-4">';
        stringHtml +=
            '<div class="image-puzzle-item" data-item="5" data-index="' +
            i +
            '" style="background-image: url(\'' +
            arrayPuzzle[i].imageOpc5 +
            '\');"></div>';
        stringHtml += '</div>';
        stringHtml += '<div class="col-12 col-sm-4">';
        stringHtml +=
            '<div class="image-puzzle-item" data-item="6" data-index="' +
            i +
            '" style="background-image: url(\'' +
            arrayPuzzle[i].imageOpc6 +
            '\');"></div>';
        stringHtml += '</div>';
        stringHtml += '</div>';
        stringHtml += '</div>';
        stringHtml += '</div>';
        stringHtml += '</div>';
    }
    innerHtmlPuzzle.innerHTML = stringHtml;
    $('#numberPagTotal').html(itemPuzzleCount);
    $('#container-puzzle .item-puzzle').addClass('d-none');
    $('#item-puzzle-' + arrayPuzzle[0].puzzleId).removeClass('d-none');
    $('#container-puzzle').on('click', '.image-puzzle-item', function (event) {
        eventClickNextPuzzle(
            event,
            $(this),
            itemPuzzleCount,
            'puzzle_test_item_',
            'container-puzzle',
            'form-puzzle-test'
        );
    });
    $('#btn-next-puzzle').on('click', function (event) {
        eventClickNext(
            event,
            itemPuzzleCount,
            'container-puzzle',
            'form-puzzle-test'
        );
    });
    $(document).on('submit', '#form-puzzle-test', function (event) {
        callAjaxFunction(event, $(this));
    });
    calculateProgressBar(itemPuzzleCount, 0);
}

if (
    $.contains(
        document.body,
        document.getElementById('container-puzzle-emotional-intelligence')
    )
) {
    var stringHtml = '';
    var numberNext = 0;
    var itemPuzzleCount = arrayPuzzle.length;
    var innerHtmlPuzzle = document.getElementById(
        'container-puzzle-emotional-intelligence'
    );
    var globalNombreTest = 'form-puzzle-test-emotional-intelligence';
    for (var i = 0; i < itemPuzzleCount; i++) {
        stringHtml +=
            '<div id="item-puzzle-' +
            arrayPuzzle[i].puzzleId +
            '" class="row item-puzzle d-none">';
        stringHtml +=
            '<input type="hidden" id="puzzle_test_item_' +
            arrayPuzzle[i].puzzleId +
            '" name="puzzle_test_item[' +
            arrayPuzzle[i].puzzleId +
            ']" value="0" />';
        stringHtml += '<div class="col-12">';
        stringHtml += '<h3>' + arrayPuzzle[i].titleMain + '</h3>';
        stringHtml += '</div>';
        stringHtml +=
            '<div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center">';
        stringHtml +=
            '<button type="button" class="btn btn-success btn-lg" data-item="' +
            arrayPuzzle[i].val1 +
            '" data-index="' +
            arrayPuzzle[i].puzzleId +
            '">' +
            arrayPuzzle[i].Opc1 +
            '</button>';
        stringHtml += '</div>';
        stringHtml +=
            '<div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center">';
        stringHtml +=
            '<button type="button" class="btn btn-success btn-lg" data-item="' +
            arrayPuzzle[i].val2 +
            '" data-index="' +
            arrayPuzzle[i].puzzleId +
            '">' +
            arrayPuzzle[i].Opc2 +
            '</button>';
        stringHtml += '</div>';
        stringHtml +=
            '<div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center">';
        stringHtml +=
            '<button type="button" class="btn btn-success btn-lg" data-item="' +
            arrayPuzzle[i].val3 +
            '" data-index="' +
            arrayPuzzle[i].puzzleId +
            '">' +
            arrayPuzzle[i].Opc3 +
            '</button>';
        stringHtml += '</div>';
        stringHtml +=
            '<div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center">';
        stringHtml +=
            '<button type="button" class="btn btn-success btn-lg" data-item="' +
            arrayPuzzle[i].val4 +
            '" data-index="' +
            arrayPuzzle[i].puzzleId +
            '">' +
            arrayPuzzle[i].Opc4 +
            '</button>';
        stringHtml += '</div>';
        stringHtml += '</div>';
    }
    innerHtmlPuzzle.innerHTML = stringHtml;
    $('#numberPagTotal').html(itemPuzzleCount);
    $('#container-puzzle-emotional-intelligence .item-puzzle').addClass('d-none');
    $('#item-puzzle-' + arrayPuzzle[0].puzzleId).removeClass('d-none');
    $('#container-puzzle-emotional-intelligence').on('click', '.btn', function (
        event
    ) {
        eventClickNextPuzzle(
            event,
            $(this),
            itemPuzzleCount,
            'puzzle_test_item_',
            'container-puzzle-emotional-intelligence',
            'form-puzzle-test-emotional-intelligence'
        );
    });
    $('#btn-next-puzzle').on('click', function (event) {
        eventClickNext(
            event,
            itemPuzzleCount,
            'container-puzzle-emotional-intelligence',
            'form-puzzle-test-emotional-intelligence'
        );
    });
    $(document).on(
        'submit',
        '#form-puzzle-test-emotional-intelligence',
        function (event) {
            callAjaxFunction(event, $(this));
        }
    );
}

if (
    $.contains(
        document.body,
        document.getElementById('container-puzzle-self-esteem')
    )
) {
    var stringHtml = '';
    var numberNext = 0;
    var itemPuzzleCount = arrayPuzzle.length;
    var innerHtmlPuzzle = document.getElementById('container-puzzle-self-esteem');
    var globalNombreTest = 'form-puzzle-test-self-esteem';
    for (var i = 0; i < itemPuzzleCount; i++) {
        stringHtml +=
            '<div id="item-puzzle-' +
            arrayPuzzle[i].puzzleId +
            '" class="row item-puzzle d-none">';
        stringHtml +=
            '<input type="hidden" id="puzzle_test_item_' +
            arrayPuzzle[i].puzzleId +
            '" name="puzzle_test_item[' +
            arrayPuzzle[i].puzzleId +
            ']" value="0" />';
        stringHtml += '<div class="col-12">';
        stringHtml += '<h3>' + arrayPuzzle[i].titleMain + '</h3>';
        stringHtml += '</div>';
        stringHtml +=
            '<div class="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-center">';
        stringHtml +=
            '<button type="button" class="btn btn-success btn-lg" data-item="' +
            arrayPuzzle[i].val1 +
            '" data-index="' +
            arrayPuzzle[i].puzzleId +
            '">' +
            arrayPuzzle[i].Opc1 +
            '</button>';
        stringHtml += '</div>';
        stringHtml +=
            '<div class="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-center">';
        stringHtml +=
            '<button type="button" class="btn btn-success btn-lg" data-item="' +
            arrayPuzzle[i].val2 +
            '" data-index="' +
            arrayPuzzle[i].puzzleId +
            '">' +
            arrayPuzzle[i].Opc2 +
            '</button>';
        stringHtml += '</div>';
        stringHtml +=
            '<div class="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-center">';
        stringHtml +=
            '<button type="button" class="btn btn-success btn-lg" data-item="' +
            arrayPuzzle[i].val3 +
            '" data-index="' +
            arrayPuzzle[i].puzzleId +
            '">' +
            arrayPuzzle[i].Opc3 +
            '</button>';
        stringHtml += '</div>';
        stringHtml +=
            '<div class="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-center">';
        stringHtml +=
            '<button type="button" class="btn btn-success btn-lg" data-item="' +
            arrayPuzzle[i].val4 +
            '" data-index="' +
            arrayPuzzle[i].puzzleId +
            '">' +
            arrayPuzzle[i].Opc4 +
            '</button>';
        stringHtml += '</div>';
        stringHtml +=
            '<div class="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-center">';
        stringHtml +=
            '<button type="button" class="btn btn-success btn-lg" data-item="' +
            arrayPuzzle[i].val5 +
            '" data-index="' +
            arrayPuzzle[i].puzzleId +
            '">' +
            arrayPuzzle[i].Opc5 +
            '</button>';
        stringHtml += '</div>';
        stringHtml += '</div>';
    }
    innerHtmlPuzzle.innerHTML = stringHtml;
    $('#numberPagTotal').html(itemPuzzleCount);
    $('#container-puzzle-self-esteem .item-puzzle').addClass('d-none');
    $('#item-puzzle-' + arrayPuzzle[0].puzzleId).removeClass('d-none');
    $('#container-puzzle-self-esteem').on('click', '.btn', function (event) {
        eventClickNextPuzzle(
            event,
            $(this),
            itemPuzzleCount,
            'puzzle_test_item_',
            'container-puzzle-self-esteem',
            'form-puzzle-test-self-esteem'
        );
    });
    $('#btn-next-puzzle').on('click', function (event) {
        eventClickNext(
            event,
            itemPuzzleCount,
            'container-puzzle-self-esteem',
            'form-puzzle-test-self-esteem'
        );
    });
    $(document).on('submit', '#form-puzzle-test-self-esteem', function (event) {
        callAjaxFunction(event, $(this));
    });
}

if (
    $.contains(
        document.body,
        document.getElementById('container-puzzle-perceptual-acuity')
    )
) {
    var index = 0;
    var numberNext = 0;
    var itemPuzzleCount = document.getElementsByClassName('item-puzzle').length;

    //inicializo contador y oculto contador al empezar.
    var accountant = document.querySelector('#accountant-time-puzzle');
    var accountantMinutes = accountant.getAttribute('time');
    var globalNombreTest = 'form-puzzle-test-perceptual-acuity';
    $('#container-puzzle-self-esteem .item-puzzle').addClass('d-none');
    $('#item-puzzle-0').removeClass('d-none');
    $('#numberPagTotal').html(itemPuzzleCount);
    startTimer(accountantMinutes, accountant, true);
    $('#container-puzzle-perceptual-acuity').on('click', 'td', function (event) {
        eventClickNextPuzzle(
            event,
            $(this),
            itemPuzzleCount,
            'puzzle_test_item_',
            'container-puzzle-perceptual-acuity',
            'form-puzzle-test-perceptual-acuity',
            false,
            true,
            9,
            true
        );
        clearInterval(internal);
        startTimer(accountantMinutes, accountant, true);
    });
    $('#btn-next-puzzle').on('click', function (event) {
        eventClickNext(
            event,
            itemPuzzleCount,
            'container-puzzle-perceptual-acuity',
            'form-puzzle-test-perceptual-acuity'
        );
        startTimer(accountantMinutes, accountant, true);
    });
    $(document).on('submit', '#form-puzzle-test-perceptual-acuity', function (
        event
    ) {
        callAjaxFunction(event, $(this));
    });
}

function initSetInterval (numberNext) {
    $('#container-puzzle-attention-and-memory .item-puzzle').addClass('d-none');
    $('#item-puzzle-' + numberNext).removeClass('d-none');
    $('#container-puzzle-attention-and-memory .image-main').removeClass('d-none');
    $('#container-puzzle-attention-and-memory .image-item').addClass('d-none');
    $('.restante').removeClass('d-none');

    setTimeout(function () {
        $('#container-puzzle-attention-and-memory .image-main').addClass('d-none');
        //oculto contador
        $('.restante').addClass('d-none');

        $('#container-puzzle-attention-and-memory .image-item').removeClass(
            'd-none'
        );
    }, 5000);
}

if (
    $.contains(
        document.body,
        document.getElementById('container-puzzle-attention-and-memory')
    )
) {
    $('#block-modal-instructions').modal('show');

    //inicializo contador y oculto contador al empezar.
    var accountant = document.querySelector('#accountant-time-puzzle');
    var accountantMinutes = accountant.getAttribute('time');
    $('.restante').addClass('d-none');
    var globalNombreTest = 'form-puzzle-test-attention-and-memory';
    var stringHtml = '';
    var numberNext = 0;
    var itemPuzzleCount = arrayPuzzle.length;
    var innerHtmlPuzzle = document.getElementById(
        'container-puzzle-attention-and-memory'
    );

    for (var i = 0; i < itemPuzzleCount; i++) {
        stringHtml +=
            '<div id="item-puzzle-' +
            arrayPuzzle[i].puzzleId +
            '" class="item-puzzle d-none">';
        stringHtml +=
            '<input type="hidden" id="puzzle_test_item_' +
            arrayPuzzle[i].puzzleId +
            '" name="puzzle_test_item[' +
            arrayPuzzle[i].puzzleId +
            ']" value="0" />';
        stringHtml += '<div class="row image-main">';
        stringHtml += '<div class="col-12">';
        stringHtml += '<h3>'+INTENTA_RECORDAR_CADA_DETALLE+'</h3>';
        stringHtml += '</div>';
        stringHtml += '<div class="col-12 text-center">';
        stringHtml +=
            '<img src="' + arrayPuzzle[i].imageMain + '" class="img-fluid">';
        stringHtml += '</div>';
        stringHtml += '</div>';
        stringHtml += '<div class="row image-item d-none">';
        stringHtml += '<div class="col-12">';
        stringHtml += '<h3>'+ELIGE_IGUAL_ANTERIOR+'</h3>';
        stringHtml += '</div>';
        stringHtml += '<div class="col-12 col-lg-4 text-center">';
        stringHtml +=
            '<img src="' +
            arrayPuzzle[i].image1 +
            '" class="img-fluid" data-item="1" data-index="' +
            arrayPuzzle[i].puzzleId +
            '">';
        stringHtml += '</div>';
        stringHtml += '<div class="col-12 col-lg-4 text-center">';
        stringHtml +=
            '<img src="' +
            arrayPuzzle[i].image2 +
            '" class="img-fluid" data-item="2" data-index="' +
            arrayPuzzle[i].puzzleId +
            '">';
        stringHtml += '</div>';
        stringHtml += '<div class="col-12 col-lg-4 text-center">';
        stringHtml +=
            '<img src="' +
            arrayPuzzle[i].image3 +
            '" class="img-fluid" data-item="3" data-index="' +
            arrayPuzzle[i].puzzleId +
            '">';
        stringHtml += '</div>';
        stringHtml += '</div>';
        stringHtml += '</div>';
    }
    innerHtmlPuzzle.innerHTML = stringHtml;
    $('#numberPagTotal').html(itemPuzzleCount);
    $('#container-puzzle-attention-and-memory').on(
        'click',
        '.image-item .img-fluid',
        function (event) {
            eventClickNextPuzzle(
                event,
                $(this),
                itemPuzzleCount,
                'puzzle_test_item_',
                'container-puzzle-attention-and-memory',
                'form-puzzle-test-attention-and-memory',
                true,
                true,
                5,
                false
            );
        }
    );
    $('#btn-next-puzzle').on('click', function (event) {
        event.preventDefault();
        //inicio contador.
        startTimer(accountantMinutes, accountant);
        initSetInterval(numberNext);
        $('#btn-next-puzzle').hide();
    });
    $(document).on('submit', '#form-puzzle-test-attention-and-memory', function (
        event
    ) {
        callAjaxFunction(event, $(this));
    });
}

if (
    $.contains(
        document.body,
        document.getElementById('container-puzzle-mental-training')
    )
) {
    function onClickRight (item, index, value) {
        $('#item-puzzle-' + index + ' .content-main').hide();
        $('#item-puzzle-' + index + ' .content-right').fadeIn();

        $('#btn-next-puzzle').removeClass('d-none');
    }

    function onClickIncorrect (item, index, value) {
        $('#item-puzzle-' + index + ' .content-main').hide();
        $('#item-puzzle-' + index + ' .content-incorrect').fadeIn();

        $('#btn-next-puzzle').addClass('d-none');
    }

    function onNextPuzzle (numberNext, itemPuzzleCount) {
        calculateProgressBar(itemPuzzleCount, numberNext);

        $('#btn-next-puzzle').addClass('d-none');

        if (numberNext < itemPuzzleCount) {
            $('#numberPagPre').html(numberNext + 1);
            $('#container-puzzle-mental-training .item-puzzle').addClass('d-none');
            $('#item-puzzle-' + numberNext).removeClass('d-none');
        } else {
            $('#form-puzzle-test-mental-training').submit();
        }
    }

    var item = 0;
    var value = 0;
    var index = 0;
    var numberNext = 0;
    var itemPuzzleCount = document.getElementsByClassName('item-puzzle').length;

    $('#container-puzzle-mental-training .item-puzzle').addClass('d-none');
    $('#item-puzzle-0').removeClass('d-none');

    $('#numberPagTotal').html(itemPuzzleCount);

    $('#container-puzzle-mental-training').on(
        'click',
        '.content-main .btn',
        function (event) {
            event.preventDefault();

            item = $(this).data('item');
            index = $(this).data('index');
            value = $('#puzzle_test_item_right_' + index).val();

            $('#puzzle_test_item_' + index).val(item);

            if (item == value) {
                onClickRight(item, index, value);
            } else {
                onClickIncorrect(item, index, value);
            }
        }
    );

    $('#container-puzzle-mental-training').on(
        'click',
        '.content-incorrect .btn-warning',
        function (event) {
            event.preventDefault();

            $('#item-puzzle-' + index + ' .content-main').fadeIn();
            $('#item-puzzle-' + index + ' .content-incorrect').hide();

            $('#btn-next-puzzle').addClass('d-none');
        }
    );

    $('#container-puzzle-mental-training').on(
        'click',
        '.content-incorrect .btn-danger',
        function (event) {
            event.preventDefault();

            numberNext = index + 1;

            onNextPuzzle(numberNext, itemPuzzleCount);
        }
    );

    $('#btn-next-puzzle').on('click', function (event) {
        event.preventDefault();

        numberNext = index + 1;

        onNextPuzzle(numberNext, itemPuzzleCount);
    });

    $(document).on('submit', '#form-puzzle-test-mental-training', function (
        event
    ) {
        callAjaxFunction(event, $(this));
    });
}

if (
    $.contains(
        document.body,
        document.getElementById('container-puzzle-letter-mess-training')
    )
) {
    function calculateProgressBar (itemPuzzleCount, numberNext) {
        var valTotal = (100 * numberNext) / itemPuzzleCount;

        $('#progress-bar-puzzle').width(valTotal + '%');
    }

    function onClickRight (item, index, value) {
        $('#item-puzzle-' + index + ' .content-main').hide();
        $('#item-puzzle-' + index + ' .content-right').fadeIn();

        $('#btn-next-puzzle').removeClass('d-none');
    }

    function onClickIncorrect (item, index, value) {
        $('#item-puzzle-' + index + ' .content-main').hide();
        $('#item-puzzle-' + index + ' .content-incorrect').fadeIn();

        $('#btn-next-puzzle').addClass('d-none');
    }

    function onNextPuzzle (numberNext, itemPuzzleCount) {
        calculateProgressBar(itemPuzzleCount, numberNext);

        $('#btn-next-puzzle').addClass('d-none');

        if (numberNext < itemPuzzleCount) {
            $('#numberPagPre').html(numberNext + 1);
            $('#container-puzzle-letter-mess-training .item-puzzle').addClass(
                'd-none'
            );
            $('#item-puzzle-' + numberNext).removeClass('d-none');
        } else {
            $('#form-puzzle-test-letter-mess-training').submit();
        }
    }

    var item = 0;
    var value = 0;
    var index = 0;
    var numberNext = 0;
    var itemPuzzleCount = document.getElementsByClassName('item-puzzle').length;

    $('#container-puzzle-letter-mess-training .item-puzzle').addClass('d-none');
    $('#item-puzzle-0').removeClass('d-none');

    $('#numberPagTotal').html(itemPuzzleCount);

    $('#container-puzzle-letter-mess-training').on(
        'click',
        '.content-main .btn',
        function (event) {
            event.preventDefault();

            item = $(this).data('item');
            index = $(this).data('index');
            value = $('#puzzle_test_item_right_' + index).val();

            $('#puzzle_test_item_' + index).val(item);

            if (item == value) {
                onClickRight(item, index, value);
            } else {
                onClickIncorrect(item, index, value);
            }
        }
    );

    $('#container-puzzle-letter-mess-training').on(
        'click',
        '.content-incorrect .btn-warning',
        function (event) {
            event.preventDefault();

            $('#item-puzzle-' + index + ' .content-main').fadeIn();
            $('#item-puzzle-' + index + ' .content-incorrect').hide();

            $('#btn-next-puzzle').addClass('d-none');
        }
    );

    $('#container-puzzle-letter-mess-training').on(
        'click',
        '.content-incorrect .btn-danger',
        function (event) {
            event.preventDefault();

            numberNext = index + 1;

            onNextPuzzle(numberNext, itemPuzzleCount);
        }
    );

    $('#btn-next-puzzle').on('click', function (event) {
        event.preventDefault();

        numberNext = index + 1;

        onNextPuzzle(numberNext, itemPuzzleCount);
    });

    $(document).on('submit', '#form-puzzle-test-letter-mess-training', function (
        event
    ) {
        callAjaxFunction(event, $(this));
    });
}

if (
    $.contains(
        document.body,
        document.getElementById('container-puzzle-mental-calculation')
    )
) {
    function calculateProgressBar (itemPuzzleCount, numberNext) {
        var valTotal = (100 * numberNext) / itemPuzzleCount;

        $('#progress-bar-puzzle').width(valTotal + '%');
    }

    function onClickRight (index) {
        $('#item-puzzle-' + index + ' .content-main').hide();
        $('#item-puzzle-' + index + ' .content-right').fadeIn();

        $('#btn-next-puzzle').removeClass('d-none');
    }

    function onClickIncorrect (index) {
        $('#item-puzzle-' + index + ' .content-main').hide();
        $('#item-puzzle-' + index + ' .content-incorrect').fadeIn();

        $('#btn-next-puzzle').addClass('d-none');
    }

    function onNextPuzzle (numberNext, itemPuzzleCount) {
        calculateProgressBar(itemPuzzleCount, numberNext);

        $('#btn-next-puzzle').addClass('d-none');

        $('.item-puzzle li span').removeClass('active');
        $('.item-puzzle .item-number-1 span').addClass('active');

        if (numberNext < itemPuzzleCount) {
            $('#numberPagPre').html(numberNext + 1);
            $('#container-puzzle-mental-calculation .item-puzzle').addClass('d-none');
            $('#item-puzzle-' + numberNext).removeClass('d-none');
        } else {
            $('#form-puzzle-test-mental-calculation').submit();
        }
    }

    var item = 0;
    var value = 0;
    var index = 0;
    var item_present = 1;
    var item_number = '';
    var numberNext = 0;
    var itemPuzzleCount = document.getElementsByClassName('item-puzzle').length;

    $('#container-puzzle-mental-calculation .item-puzzle').addClass('d-none');
    $('#item-puzzle-0').removeClass('d-none');

    $('#numberPagTotal').html(itemPuzzleCount);

    $(
        '#item-puzzle-' + index + ' .item-number-' + item_present + ' span'
    ).addClass('active');

    $('#container-puzzle-mental-calculation').on(
        'click',
        '.content-main .btn-number',
        function (event) {
            event.preventDefault();

            item = $(this).data('item');
            index = $(this).data('index');

            item_number += item;

            $(
                '#item-puzzle-' + index + ' .puzzle_test_item_result_' + item_present
            ).val(item_number);
            $(
                '#item-puzzle-' +
                index +
                ' .content-main .item-number-' +
                item_present +
                ' span'
            ).html(item_number);
        }
    );

    $('#container-puzzle-mental-calculation').on(
        'click',
        '.content-main .btn-equal',
        function (event) {
            event.preventDefault();

            item_present++;

            item_number = '';

            $('#item-puzzle-' + index + ' li span').removeClass('active');
            $(
                '#item-puzzle-' + index + ' .item-number-' + item_present + ' span'
            ).addClass('active');

            if (item_present > 4) {
                item_present = 1;

                var isValid = 0;

                for (var i = 1; i <= 4; i++) {
                    if (
                        $(
                            '#item-puzzle-' + index + ' .content-main .item-number-' + i
                        ).data('result') !=
                        $('#item-puzzle-' + index + ' .puzzle_test_item_result_' + i).val()
                    ) {
                        isValid++;
                    }
                }

                if (isValid == 0) {
                    onClickRight(index);
                } else {
                    onClickIncorrect(index);
                }
            }
        }
    );

    $('#container-puzzle-mental-calculation').on(
        'click',
        '.content-incorrect .btn-warning',
        function (event) {
            event.preventDefault();

            $('#item-puzzle-' + index + ' .content-main').fadeIn();
            $('#item-puzzle-' + index + ' .content-incorrect').hide();

            $('#btn-next-puzzle').addClass('d-none');

            for (var i = 1; i <= 4; i++) {
                $('#item-puzzle-' + index + ' .puzzle_test_item_result_' + i).val('0');
                $(
                    '#item-puzzle-' + index + ' .content-main .item-number-' + i + ' span'
                ).html('-');
            }
        }
    );

    $('#container-puzzle-mental-calculation').on(
        'click',
        '.content-incorrect .btn-danger',
        function (event) {
            event.preventDefault();

            numberNext = index + 1;

            onNextPuzzle(numberNext, itemPuzzleCount);
        }
    );

    $('#btn-next-puzzle').on('click', function (event) {
        event.preventDefault();

        numberNext = index + 1;

        onNextPuzzle(numberNext, itemPuzzleCount);
    });

    $(document).on('submit', '#form-puzzle-test-mental-calculation', function (
        event
    ) {
        callAjaxFunction(event, $(this));
    });
}

if (
    $.contains(
        document.body,
        document.getElementById('container-puzzle-game-zoo-puzzle')
    )
) {
    function offsetTop () {
        $('html, body').animate(
            {
                scrollTop: $('#container-puzzle-game-zoo-puzzle').offset().top - 200
            },
            1000
        );
    }

    function calculateProgressBar (itemPuzzleCount, numberNext) {
        offsetTop();

        var valTotal = (100 * numberNext) / itemPuzzleCount;

        $('#progress-bar-puzzle').width(valTotal + '%');
    }

    function onClickRight (index) {
        setTimeout(function () {
            offsetTop();

            $('#item-puzzle-' + index + ' .content-main').hide();
            $('#item-puzzle-' + index + ' .content-right').fadeIn();

            $('#btn-next-puzzle').removeClass('d-none');
        }, 1000);
    }

    function onClickIncorrect (index) {
        offsetTop();

        $('#item-puzzle-' + index + ' .content-main').hide();
        $('#item-puzzle-' + index + ' .content-second-test').hide();
        $('#item-puzzle-' + index + ' .content-right').hide();
        $('#item-puzzle-' + index + ' .content-incorrect').fadeIn();

        $('#btn-next-puzzle').addClass('d-none');
    }

    function onNextPuzzle (numberNext, itemPuzzleCount) {
        calculateProgressBar(itemPuzzleCount, numberNext);

        $('#btn-next-puzzle').addClass('d-none');

        if (numberNext < itemPuzzleCount) {
            $('#numberPagPre').html(numberNext + 1);
            $('#container-puzzle-game-zoo-puzzle .item-puzzle').addClass('d-none');
            $('#item-puzzle-' + numberNext).removeClass('d-none');
        } else {
            $('#form-puzzle-test-game-zoo-puzzle').submit();
        }
    }

    var index = 0;
    var index_count = 0;
    var numberNext = 0;
    var itemPuzzleCount = document.getElementsByClassName('item-puzzle').length;

    $('#container-puzzle-game-zoo-puzzle .item-puzzle').addClass('d-none');
    $('#item-puzzle-0').removeClass('d-none');

    $('#numberPagTotal').html(itemPuzzleCount);

    $('#container-puzzle-game-zoo-puzzle').on(
        'click',
        '.content-main .btn-item',
        function (event) {
            event.preventDefault();

            item = $(this).data('item');
            index = $(this).data('index');

            if (item != 0) {
                index_count++;

                $('#item-puzzle-' + index + ' .opt-right-' + item).fadeIn();

                var item_count = $(
                    '#item-puzzle-' + index + ' .puzzle_test_item_count_' + index
                ).val();

                if (item_count == index_count) {
                    onClickRight(index);
                }
            }
        }
    );

    $('#container-puzzle-game-zoo-puzzle').on(
        'click',
        '.content-second-test .btn',
        function (event) {
            event.preventDefault();

            var second_item = $(this).data('item');
            var second_index = $(this).data('index');

            if (second_item != 0) {
                offsetTop();

                $('#item-puzzle-' + index + ' .content-main').hide();
                $('#item-puzzle-' + index + ' .content-second-test').hide();
                $('#item-puzzle-' + index + ' .content-incorrect').hide();
                $('#item-puzzle-' + index + ' .content-right').hide();
                $('#item-puzzle-' + index + ' .content-finished').fadeIn();

                $('#btn-next-puzzle').addClass('d-none');

                setTimeout(function () {
                    index_count = 0;
                    numberNext = index + 1;
                    $('.puzzle_test_item_result_' + second_index).val(second_item);
                    onNextPuzzle(numberNext, itemPuzzleCount);
                }, 3000);
            } else {
                onClickIncorrect(index);
            }
        }
    );

    $('#container-puzzle-game-zoo-puzzle').on(
        'click',
        '.content-incorrect .btn-warning',
        function (event) {
            event.preventDefault();

            offsetTop();

            $('#item-puzzle-' + index + ' .content-main').hide();
            $('#item-puzzle-' + index + ' .content-second-test').hide();
            $('#item-puzzle-' + index + ' .content-incorrect').hide();
            $('#item-puzzle-' + index + ' .content-right').fadeIn();

            $('#btn-next-puzzle').removeClass('d-none');
        }
    );

    $('#container-puzzle-game-zoo-puzzle').on(
        'click',
        '.content-incorrect .btn-danger',
        function (event) {
            event.preventDefault();

            index_count = 0;

            numberNext = index + 1;

            onNextPuzzle(numberNext, itemPuzzleCount);
        }
    );

    $('#btn-next-puzzle').on('click', function (event) {
        event.preventDefault();

        offsetTop();

        $('#item-puzzle-' + index + ' .content-main').hide();
        $('#item-puzzle-' + index + ' .content-incorrect').hide();
        $('#item-puzzle-' + index + ' .content-right').hide();
        $('#item-puzzle-' + index + ' .content-second-test').fadeIn();

        $('#btn-next-puzzle').addClass('d-none');
    });

    $(document).on('submit', '#form-puzzle-test-game-zoo-puzzle', function (
        event
    ) {
        callAjaxFunction(event, $(this));
    });
}

if ($('#emailFinalTest').length > 0) {
    var formEmail = document.getElementById('emailFinalTest');

    formEmail.addEventListener('submit', function (event) {
        event.preventDefault();
        $('#modal-form-email-puzzle').modal('hide');
        callAjaxFunction(event, $('#' + globalNombreTest));
    });
}
