import './scss/style.scss';
import 'bootstrap';
import 'url-polyfill';
import { WOW } from 'wowjs';
import { Pagination, Swiper } from 'swiper/js/swiper.esm.js';
import '../../node_modules/jquery-form/dist/jquery.form.min.js';
import '../testci_es/puzzle.js';


function startTimer(duration, display) {
	var timer = duration,
		minutes,
		seconds;
	setInterval(function () {
		minutes = parseInt(timer / 60, 10);
		seconds = parseInt(timer % 60, 10);

		minutes = minutes < 10 ? "0" + minutes : minutes;
		seconds = seconds < 10 ? "0" + seconds : seconds;

		display.textContent = minutes + ":" + seconds;

		if (--timer < 0) {
			timer = duration;
		}
	}, 1000);
}

if ($.contains(document.body, document.getElementById("slider-main"))) {
	Swiper.use([Pagination]);

	var mySwiper = new Swiper(".swiper-container", {
		autoplay: {
			delay: 5000,
		},
		pagination: {
			el: ".swiper-pagination",
			type: "bullets",
			clickable: true,
		},
		loop: true,
	});
}

var wow = new WOW({
	live: false,
	scrollContainer: null,
});

wow.init();

$("#block-modal-terms-of-service").modal("show");

$(document).ready(function () {
	/*Baja*/
	if ($("#btn-cancelar-cuenta").length > 0) {
		var form = document.getElementById("form-baja");

		form.addEventListener("submit", function (event) {
			event.preventDefault();

			$.ajax({
					cache: false,
					type: "POST",
					url: "/index.php",
					data: $("#form-baja").serialize() + "&action=cancel",
				})
				.done(function (data) {
					var content = JSON.parse(data);

					if (content.status) {
						$("#form-baja .alert-success").html(content.info);
						$("#form-baja .alert-success").fadeIn();
					} else {
						$("#form-baja .alert-danger").html(content.info);
						$("#form-baja .alert-danger").fadeIn();
					}
				})
				.fail(function () {
					alert(
						ERROR_CANCEL_ACCOUNT
					);
					$("#Ds_Merchant_PayButton").attr("disabled", false);
				})
				.always(function () {
					setTimeout(function () {
						$("#form-baja .alert").hide();
					}, 10000);
				});
		});
	}
});

/*RECUPERAR CONTRASEÑA*/
$("#password_forgotten").on("submit", function (event) {
	event.preventDefault();

	$.ajax({
		cache: false,
		type: "POST",
		url: "/password_forgotten.php",
		data: $(this).serialize(),
	})
		.done(function (data) {
			var content = JSON.parse(data);

			if (content.status) {
				$("#password_forgotten .alert-success").html(content.info);
				$("#password_forgotten .alert-success").removeClass("hidden");
				$("#password_forgotten .alert-success").fadeIn();

				setTimeout(function () {
					//								$(location).attr('href', '/usuarios/enviar-mensaje/');
					$(location).attr("href", "/login/");
				}, 4000);
			} else {
				$("#password_forgotten .alert-danger").html(content.info);

				if (typeof content.extrainfo !== "undefined") {
					$("#password_forgotten  .alert-danger").html(
						content.info + " --- " + content.extrainfo
					);
				}
				$("#password_forgotten .alert-danger").removeClass("hidden");
				$("#password_forgotten .alert-danger").fadeIn();
			}
		})
		.fail(function () {
			alert(ERROR_OCCURRED);
		})
		.always(function () {
			setTimeout(function () {
				$("#password_forgotten .alert").addClass("hidden");
				$("#password_forgotten .alert").hide();
			}, 10000);
		});
});

if ($.contains(document.body, document.getElementById("accountant-time"))) {
	var accountant = document.querySelector("#accountant-time");
	var accountantMinutes = 60 * accountant.getAttribute("time");
	startTimer(accountantMinutes, accountant);
}
/*paycomet*/
//modal-form-locate-credicard
if ($("#modal-form-locate-credicard").length > 0) {
	createFormPaycomet();
}

function createFormPaycomet() {
	var form = document.getElementById("paycometPaymentForm");

	if (form !== null) {
		form.addEventListener("submit", function (event) {
			$("#Ds_Merchant_PayButton").attr("disabled", true);
			event.preventDefault();

			setTimeout(function () {
				// if ()
				//console.log($("#paycometPaymentForm").serialize());
				$("#paymentErrorMsg").html(PROCESSING_PAYMENT);
				if (
					typeof $("#paycometPaymentForm")
						.find('input[name="paytpvToken"]')
						.val() !== "undefined"
				) {
					$.ajax({
							cache: false,
							type: "POST",
							url: "/sabadell.php",
							data: $("#paycometPaymentForm").serialize() + "&action=payment",
						})
						.done(function (data) {
							$("#paycometPaymentForm .alert").hide();
							var content = JSON.parse(data);
							if (content.status) {
								$("#paycometPaymentForm")[0].reset();
								$("#paycometPaymentForm .alert-success").html(content.info);
								$("#paycometPaymentForm .alert-success").fadeIn();
								console.log("evento enviado");
								gtag("event", "conversion", {
									send_to: ADS_ID,
									transaction_id: "",
								});
								setTimeout(function () {
									$(location).attr("href", "/back/index.php?entity=10");
								}, 3000);
							} else {
								$("#paymentErrorMsg").html(content.info);
								if (typeof content.extrainfo !== "undefined") {
									$("#paymentErrorMsg").html(
										content.info + " --- " + content.extrainfo
									);
								}
							}
						})
						.fail(function () {
							alert(ERROR_PAYMENT);
						})
						.always(function () {
							setTimeout(function () {
								$("#paycometPaymentForm .alert").hide();
								$("#paymentErrorMsg").html("");
							}, 10000);
							$("#Ds_Merchant_PayButton").attr("disabled", false);
						});
				} else {
					$("#paymentErrorMsg").html(
						$("#paymentErrorMsg").text() +
						" --> " + ERROR_PROCESSING_PAYMENT
					);
					$("#Ds_Merchant_PayButton").attr("disabled", false);
				}
			}, 3000);
		});
	}
}

// La usa el botón de pago, aunque no lo parezca
function submitFormOnlyOnce(component) {
	component.disabled=true;
	component.form.submit();
	return true;
}
